import React, { FC, ReactElement, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  LinearProgress,
  linearProgressClasses,
  TextField,
  Typography,
} from "@mui/material";
import { BadgeIcon } from "../../../icons/BadgeIcon";
import { BookIcon } from "../../../icons/BookIcon";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ChevronRight } from "@mui/icons-material";
import {
  getAllOneToOneByEtudiant,
  getGeneralFormationInfo,
  getWorkshopByEtudiantFormation,
} from "../../../api";
import {
  getCountSousChapitreDoneFormationByStudent,
  getFormationFormateur,
  getGeneralProgressionOnlyByStudent,
  getLastFourSouschapiterDone,
} from "../../../api/formation.service";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { User } from "../../../api/models";
import { OneToOneBalance } from "../../../component/StudentOneToOneBalance";
import { getProjectStudentCount } from "../../../api/project.service";
import { getUrl } from "../../../utils/utils";
import { RootState } from "../../../store";
import { calendar, course as courseUri } from "../../../routes/student.routes";

export const StatCard: FC<{
  color: string;
  value: number;
  label: string;
  icon: ReactElement;
}> = (props) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        borderRadius: 12,
        padding: 24,
        alignItems: "center",
        backgroundColor: props.color,
      }}
    >
      {props.icon}
      <div
        style={{
          marginLeft: 20,
        }}
      >
        <Typography style={{ fontSize: 24, fontWeight: "bold" }}>
          {props.value}
        </Typography>
        <Typography
          style={{
            color: "#8C8FA5",
            marginTop: 4,
          }}
        >
          {props.label}
        </Typography>
      </div>
    </div>
  );
};

const Dot = () => (
  <div
    style={{
      width: 16,
      height: 16,
      backgroundColor: "#023047",
      borderRadius: "50%",
    }}
  >
    &nbsp;
  </div>
);

const Progression: FC<{
  sousChapitreName: string;
  progressionValue: number;
}> = (props) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Dot />
        <Typography style={{ marginLeft: 16 }}>
          {props.sousChapitreName}
        </Typography>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ flexGrow: 1 }}>
          <LinearProgress
            variant={"determinate"}
            value={props.progressionValue}
            sx={{
              minWidth: 200,
              borderRadius: 17,
              height: 10,
              backgroundColor: "#e8e8e8",
              [`& .${linearProgressClasses.bar}`]: {
                borderRadius: 17,
              },
            }}
          />
        </div>
        <div
          style={{
            marginLeft: 12,
            fontWeight: "normal",
            color: "#969696",
            fontSize: 14,
          }}
        >
          {props.progressionValue}%
        </div>
      </div>
    </div>
  );
};

export function Events() {
  const auth = useSelector((state) => (state as any).auth);
  const [OneToOneData, setOneToOneData] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [events, setEvents] = useState([]);

  const currentFormation = useSelector(
    (state: RootState) => state.student.currentCourseId
  );

  useEffect(() => {
    if (currentFormation) {
      Promise.all([
        getWorkshopByEtudiantFormation({
          etudiantId: auth?.user?.id,
          formationId: currentFormation,
        }).then((data: any) => {
          return data.data;
        }),
        getAllOneToOneByEtudiant({
          userId: auth.user?.id,
          idFormation: currentFormation,
        }).then((data: any) => {
          return data.data;
        }),
      ]).then(([workshops, oneToOnes]) => {
        setWorkshops(workshops);
        setOneToOneData(oneToOnes);
        let result = [...OneToOneData, ...workshops];
        result.sort((a: any, b: any) => {
          let dateA = a.date || a.dateDebut;
          let dateB = b.date || b.dateDebut;

          return dateA && dateB ? moment(dateA).diff(moment(dateB)) : 0;
        });
        if (result.length !== events.length) setEvents(result as any);
      });
    }
  }, [currentFormation, events]);

  const convertDate = (inputFormat: string): string => {
    return moment(inputFormat).format("DD MMMM YYYY");
  };

  return (
    <div>
      {events?.map((event: any, i: number) => (
        <div key={i}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item>
              <Grid container alignItems={"center"}>
                <Dot />
                <Typography style={{ marginLeft: 16 }}>
                  {convertDate(event.date || event.dateDebut)}
                </Typography>
                <Typography
                  style={{
                    marginLeft: 30,
                    fontWeight: "bold",
                    color: event.title === undefined ? "#048B9A" : "#FFB703",
                  }}
                >
                  {event.title || "One To One"}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <ChevronRight />
            </Grid>
          </Grid>
          {i !== events?.length - 1 && <Divider style={{ color: "#e8e8e8" }} />}
        </div>
      ))}
    </div>
  );
}

export function Dashboard() {
  const [courseName, setCourseName] = useState("N/A");
  const [date] = useState(new Date());
  const [generalProgression, setGeneralProgression] = useState<number>(0);
  const [projectDoneCount, setProjectDoneCount] = useState<number>(0);
  const [allSousChapitreDone, setAllSousChapitreDone] = useState<number>(0);
  const auth = useSelector((state) => (state as any).auth);
  const [lastFourSousChapiterDone, setLastFourSousChapiterDone] = useState<
    Array<{
      score: number;
      qcm: {
        souschapitre: {
          title: string;
        };
      };
    }>
  >([
    {
      score: 100,
      qcm: {
        souschapitre: {
          title: "Sous chapitre loading...",
        },
      },
    },
  ]);

  const authUser = useSelector((state) => (state as any).auth?.user || null);
  const [countOneToOne, setCountOneToOne] = useState(0);
  const [OneToOneData, setOneToOneData] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [events, setEvents] = useState([]);
  const [formateur, setFormateur] =
    useState<Pick<User, "nom" | "prenom" | "id">>();

  const currentFormation = useSelector((state) => {
    return (state as any).course?.current;
  });
  const courseSelector = useSelector((state: RootState) => state.student);
  const course = courseSelector.courses.find(
    (item: any) => item.id === courseSelector.currentCourseId
  );

  const navigate = useNavigate();

  const arrayReverseObj = (obj: any) => {
    let newArray: Array<any> = [];

    Object.keys(obj)
      .sort()
      .reverse()
      .forEach((key) => {
        newArray.push(obj[key]);
      });

    return newArray;
  };

  const removeEmpty = (obj: any) => {
    let newObj: any = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] === Object(obj[key])) newObj[key] = removeEmpty(obj[key]);
      else if (obj[key] !== undefined) newObj[key] = obj[key];
    });
    return newObj;
  };

  useEffect(() => {
    if (!!currentFormation) {
      Promise.all([
        getAllOneToOneByEtudiant({
          userId: auth.user?.id,
          idFormation: currentFormation,
        }).then((data: any) => {
          return data.data;
        }),
        getGeneralFormationInfo(currentFormation).then((data) => {
          return data.data.title;
        }),
        getFormationFormateur(currentFormation).then(
          (data: Pick<User, "nom" | "prenom" | "id">) => {
            return data;
          }
        ),
        getLastFourSouschapiterDone(currentFormation).then((data) => {
          return data;
        }),
        getGeneralProgressionOnlyByStudent(currentFormation).then((data) => {
          return data.total;
        }),
        getProjectStudentCount(currentFormation).then((data) => {
          return data.total;
        }),
        getCountSousChapitreDoneFormationByStudent(currentFormation).then(
          (data) => {
            return data.total;
          }
        ),
      ]).then(
        ([
          OneToOne,
          courseName,
          formateurName,
          lastFourSouschapiterDone,
          generalProgression,
          projectDoneCount,
          allSousChapitreDone,
        ]) => {
          setOneToOneData(OneToOne);
          setCountOneToOne(OneToOne.length);
          setCourseName(OneToOne.title);
          setCourseName(courseName);
          setFormateur(formateurName);
          setGeneralProgression(generalProgression);
          setProjectDoneCount(projectDoneCount);
          setAllSousChapitreDone(allSousChapitreDone);
          let tempArray = lastFourSouschapiterDone.map((element: any) => {
            if (element["score"] >= element["qcm"]["score"]) {
              return element;
            }
          });
          tempArray = removeEmpty(tempArray);
          tempArray =
            tempArray.length > 4
              ? arrayReverseObj(tempArray).slice(3, tempArray.length)
              : arrayReverseObj(tempArray);
          setLastFourSousChapiterDone(tempArray);
        }
      );
    }
  }, [currentFormation]);

  return (
    <div className={"m-5"}>
      <Grid container justifyContent={"space-between"}>
        <Grid item>
          <Typography
            style={{
              fontSize: 24,
              fontWeight: "bold",
            }}
          >
            Bonjour{" "}
            <span
              style={{
                color: "#048B9A",
              }}
            >
              {authUser.nom}
            </span>
            <br />
            Vous avez une progression de {generalProgression}%
            <br />
            <span
              style={{
                color: "#048B9A",
              }}
            >
              {courseName}
            </span>
          </Typography>
          <Button
            style={{
              fontWeight: "bold",
              marginTop: 30,
              backgroundColor: "black",
              color: "white",
              textTransform: "capitalize",
              paddingLeft: 24,
              paddingRight: 24,
            }}
            onClick={() => {
              navigate(courseUri);
            }}
          >
            Reprendre votre cours
          </Button>
        </Grid>
        <Grid item>
          <OneToOneBalance />
        </Grid>
      </Grid>
      <div
        style={{
          marginTop: 36,
        }}
      >
        <Typography
          style={{
            fontSize: 20,
            color: "#0D2646",
            fontWeight: "bold",
            marginBottom: 18.5,
          }}
        >
          Formation
        </Typography>
        <Grid container lg={12} item>
          <Grid container spacing={2}>
            <Grid item lg={3} xs={12}>
              <StatCard
                color="#CDE8F480"
                value={allSousChapitreDone}
                label={"Sous-Chapitres Terminés"}
                icon={<BadgeIcon color={"#157afe"} />}
              />
            </Grid>
            <Grid item lg={3} xs={12}>
              <StatCard
                color="#FFF6DF80"
                value={projectDoneCount}
                label={"Projets"}
                icon={<BookIcon />}
              />
            </Grid>
            <Grid item lg={3} xs={12}>
              <StatCard
                color="#F6F2FF"
                value={countOneToOne}
                label={"One To One"}
                icon={<BadgeIcon color={"#8b5cf4"} />}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Divider
        style={{
          marginTop: 50,
          marginBottom: 30,
          color: "red",
        }}
      />

      <Grid container spacing={9}>
        <Grid item lg={3} xs={12}>
          <Typography
            style={{
              fontSize: 20,
              color: "#0D2646",
              fontWeight: "bold",
              marginBottom: 30,
            }}
          >
            Calendrier
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StaticDatePicker
              orientation={"landscape"}
              openTo="day"
              value={date}
              onChange={() => {}}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography
            style={{
              fontSize: 20,
              color: "#0D2646",
              fontWeight: "bold",
              marginBottom: 30,
            }}
          >
            Performance
          </Typography>
          <Grid container flexDirection={"column"} spacing={2}>
            {!!lastFourSousChapiterDone &&
              lastFourSousChapiterDone.length > 0 &&
              lastFourSousChapiterDone.map((element: any, index: number) => {
                if (element["score"] >= element["qcm"]["score"])
                  return (
                    <Grid item key={index}>
                      <Progression
                        sousChapitreName={
                          element["qcm"]["souschapitre"]["title"]
                        }
                        progressionValue={element["score"]}
                      />
                    </Grid>
                  );
              })}
          </Grid>
          <Divider
            style={{ color: "#e8e8e8", marginTop: 30, marginBottom: 40 }}
          />
          <Typography
            style={{
              fontSize: 20,
              color: "#0D2646",
              fontWeight: "bold",
              marginBottom: 30,
            }}
          >
            Événement À Venir
          </Typography>
          <div>
            <Events />
          </div>
        </Grid>
        <Grid item lg={3} xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 30,
            }}
          >
            <Typography
              style={{
                color: "#0D2646",
                fontWeight: "bold",
                marginBottom: 40,
                fontSize: 20,
              }}
            >
              Formateur
            </Typography>
            <Avatar
              style={{ width: "15vh", height: "15vh" }}
              src={getUrl(course?.formateur?.user?.userImage)}
            />
            <Typography
              style={{
                color: "#0D2646",
                marginTop: 20,
                fontSize: 20,
              }}
            >
              {formateur?.prenom} {formateur?.nom}
            </Typography>
            <Button
              variant={"contained"}
              style={{
                textTransform: "capitalize",
                fontSize: ".75rem",
                backgroundColor: "#023047",
                color: "white",
                fontWeight: "normal",
              }}
              className={"mt-3"}
              onClick={() => {
                navigate(calendar);
              }}
            >
              Demander un one to one
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
