import { useSelector } from "react-redux";
import { getCoursesWithWorkshops } from "../../../api/instructor.service";
import { Formation } from "../../../api/models";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Illustration from "../../../images/Course illustration.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../store";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "../../../hooks/snackbar";
import { useEffect } from "react";
import { Empty } from "../../../component/Empty";
import { workshopOfCourse } from "../../../routes/instructor.routes";

function getFulfilledWorkshops(course: Formation): number {
  return course.chapitres
    .map((chapter) => chapter.Workshop)
    .filter((workshop) => {
      return !!workshop?.date && moment(workshop.date).isBefore(moment());
    }).length;
}

export function WorkshopsPage() {
  const userId = useSelector((state: RootState) => state.auth.user!!.id);
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const query = useQuery({
    queryKey: ["getWorkshopsOfInstructor", userId],
    queryFn: () =>
      getCoursesWithWorkshops(userId).then((response) => response.data),
  });

  useEffect(() => {
    if (query.isError) {
      snackbar.show("Erreur lors du chargement des workshops", "error");
    }
  }, [query.isError]);

  if (query.isLoading) {
    return <></>;
  }

  return (
    <div style={{ padding: "1rem" }}>
      {!!query.data && query.data.length > 0 && (
        <Grid container spacing={2}>
          {query.data?.map((course) => (
            <Grid item md={4} key={course.id}>
              <Card
                style={{
                  backgroundColor: "#FAFAFA",
                  padding: "0.5rem 1.5rem",
                  border: "1px solid #E8E8E8",
                }}
              >
                <CardContent>
                  <Grid
                    container
                    direction={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Grid item>
                      <Typography
                        style={{
                          color: "#023047",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Formation
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{
                          color: "#048B9A",
                          fontWeight: "bold",
                          textAlign: "center",
                          minHeight: 48,
                        }}
                      >
                        {course.title}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <div style={{ padding: "1rem 0" }}>
                        <Divider color={"#E8E8E8"} />
                      </div>
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{ textAlign: "center", color: "#023047" }}
                      >
                        <span style={{ fontSize: 42, fontWeight: "bold" }}>
                          {getFulfilledWorkshops(course)}
                        </span>
                        /
                        {
                          course.chapitres
                            .map((chapter) => chapter.Workshop)
                            .filter(Boolean).length
                        }
                      </Typography>
                      <Typography
                        style={{
                          color: "#048B9A",
                          fontSize: 18,
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        Workshops Réalisés
                      </Typography>
                    </Grid>
                    <Grid item container justifyContent={"center"}>
                      <img
                        style={{
                          padding: "2rem 0",
                        }}
                        alt={"illustration"}
                        src={Illustration}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        fullWidth
                        style={{
                          backgroundColor: "black",
                          color: "white",
                        }}
                        onClick={() => {
                          navigate(workshopOfCourse(course.id));
                        }}
                      >
                        Liste des Workshops
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      {query.data?.length === 0 && (
        <Empty message={"Pas de workshops pour le moment"} />
      )}
    </div>
  );
}
