import React, { FC, useState } from "react";
import { Formation } from "../../../../api/models";
import { CourseStudentsDialog } from "./CourseStudents";
import { CourseAssign } from "./CourseAssign";
import { CourseDelete } from "./CourseDelete";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useMenu } from "../../../../hooks/hooks";

export const CourseActionMenu: FC<{
  course: Formation;
  editLink: string;
  onEditOneToOne: () => void;
  onDelete: () => void;
}> = (props) => {
  const id = "basic-menu";
  const [isListOpen, setIsListOpen] = useState(false);
  const [isAssignmentOpen, setIsAssignmentOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const menu = useMenu();

  const handleSelect = () => {
    menu.close();
    props.onEditOneToOne();
  };

  return (
    <div>
      <CourseStudentsDialog
        isOpen={isListOpen}
        onClose={() => {
          setIsListOpen(false);
          menu.close();
        }}
        courseId={props.course.id}
      />
      <CourseAssign
        courseId={props.course.id}
        isOpen={isAssignmentOpen}
        onClose={() => {
          setIsAssignmentOpen(false);
          menu.close();
        }}
      />
      <CourseDelete
        isOpen={isDeleteOpen}
        courseId={props.course.id}
        courseTitle={props.course.title}
        onOpen={() => {
          setIsDeleteOpen(true);
        }}
        onClose={() => {
          setIsDeleteOpen(false);
        }}
        onDelete={() => {
          props.onDelete();
        }}
      />
      <IconButton
        id="basic-button"
        aria-controls={menu.isOpen ? id : undefined}
        aria-haspopup="true"
        aria-expanded={menu.isOpen ? "true" : undefined}
        onClick={menu.open}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id={id}
        anchorEl={menu.anchor}
        open={menu.isOpen}
        onClose={menu.close}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            menu.close();
            setIsListOpen(true);
          }}
        >
          Liste des étudiants
        </MenuItem>
        <Link style={{ color: "inherit" }} to={props.editLink}>
          <MenuItem>Editer</MenuItem>
        </Link>
        <MenuItem
          onClick={() => {
            menu.close();
            setIsAssignmentOpen(true);
          }}
          children={"Affecter des étudiants"}
        />
        <MenuItem onClick={handleSelect}>Changer solde One To One</MenuItem>
        <MenuItem
          onClick={() => {
            menu.close();
            setIsDeleteOpen(true);
          }}
        >
          Supprimer
        </MenuItem>
      </Menu>
    </div>
  );
};
