import axios from "axios";
import {
  Disponibilite,
  EtudiantProject,
  Formateur,
  Formation,
  OneToOne,
  User,
  Workshop,
} from "./models";
import { baseUrl } from "../env";

const uri = `${baseUrl}/formateur`;

export function getCourses(userId: number) {
  return axios.get<Formation[]>(`${uri}/${userId}/courses`);
}

export function getOneToOne(userId: number) {
  return axios.get<OneToOne[]>(`${uri}/${userId}/one-to-one`);
}

export function getSlots(userId: number) {
  return axios.get<{
    slots: Disponibilite[];
    total: number;
  }>(`${baseUrl}/formateur/${userId}/slots`);
}

export function getCoursesWithWorkshops(userId: number) {
  return axios.get<Formation[]>(`${uri}/${userId}/courses/workshops`);
}

export function getProjects(userId: number) {
  return axios.get<EtudiantProject[]>(`${uri}/${userId}/projects`);
}

export function getCalendarEvents(
  userId: number,
  dateStart: Date,
  dateEnd: Date
) {
  return axios.get<{
    workshops: Workshop[];
    projects: EtudiantProject[];
    oneToOnes: OneToOne[];
  }>(`${uri}/${userId}/calendar`, {
    params: {
      dateStart,
      dateEnd,
    },
  });
}

export function getAll() {
  return axios.get<Formateur[]>(`${uri}/`);
}

export function create(user: User) {
  return axios.post(`${uri}/`, user);
}

export function update(id: number, instructor: Partial<Formateur>) {
  return axios.put<Formateur>(`${uri}/${id}`, instructor);
}
