import React, { FC, useEffect, useState } from "react";
import {
  SousChapitre,
  STUDENT_QUIZ_SUCCESS,
  StudentQuiz,
} from "../../../../api/models";
import { Box, IconButton, Stack, SvgIconProps } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { ContentOfQuiz } from "./ContentOfQuiz";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { SubchapterStepper } from "./SubchapterStepper";
import { hasQuiz } from "../../../../api/subchapter.service";
import { CourseSelection } from "../dto";
import {
  createFromPage,
  createFromQuiz,
} from "../../../../api/course-selection";
import moment from "moment";

const defaultIndex = 0;
type Props = {
  index?: number;
  subchapter: SousChapitre;
  studentQuizzes: StudentQuiz[];
  onQuizSuccess: (studentQuiz: StudentQuiz) => void;
  onQuizFail: (studentQuiz: StudentQuiz) => void;
  onChangeIndex: (nextSelection: CourseSelection) => void;
  onQuizNext: () => void;
};

export const ContentOfSubchapter: FC<Props> = (props) => {
  const [index, setIndex] = useState(props.index || defaultIndex);
  const disabledColor = "#e8e8e8";
  const enabledColor = "black";
  let arrowSx: SvgIconProps["sx"] = {
    fontSize: 40,
    color: enabledColor || disabledColor,
  };
  const _hasQuiz = hasQuiz(props.subchapter);
  const numberOfPages = props.subchapter.pages.length;
  const numberOfItems = numberOfPages + Number(_hasQuiz);
  const nextButtonIsDisabled = index === numberOfItems - 1;
  const previousButtonIsDisabled = index === 0;
  const isPageIndex = index < numberOfPages;
  const isQuizIndex = _hasQuiz && index === numberOfPages;
  const currentCourseId = useSelector(
    (state: RootState) => state.student.currentCourseId
  );

  const handleIndexChange = () => {
    if (!currentCourseId) {
      return;
    }
    if (isPageIndex) {
      props.onChangeIndex(createFromPage(props.subchapter.pages[index], index));
    } else if (isQuizIndex) {
      props.onChangeIndex(createFromQuiz(props.subchapter.qcm));
    }
  };

  useEffect(() => {
    setIndex(props.index || defaultIndex);
  }, [props.subchapter]);

  useEffect(() => {
    handleIndexChange();
  }, [index]);

  return (
    <Stack
      justifyContent={"space-between"}
      sx={{
        height: "100%",
      }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        sx={{
          height: "95%",
        }}
      >
        <Box>
          <IconButton
            disabled={previousButtonIsDisabled}
            onClick={() => {
              setIndex(Math.max(index - 1, 0));
            }}
          >
            <ChevronLeft
              sx={{
                ...arrowSx,
                color: previousButtonIsDisabled ? disabledColor : enabledColor,
              }}
            />
          </IconButton>
        </Box>
        <Box
          className={"container"}
          sx={{
            flex: 1,
            height: "100%",
            overflowY: "auto",
          }}
        >
          {isPageIndex && (
            <div
              dangerouslySetInnerHTML={{
                __html: props.subchapter.pages[index]?.contenu_Html,
              }}
            ></div>
          )}
          {isQuizIndex && (
            <ContentOfQuiz
              subchapter={props.subchapter}
              studentQuiz={
                props.studentQuizzes
                  .filter(
                    (studentQuiz) => studentQuiz.status === STUDENT_QUIZ_SUCCESS
                  )
                  .sort(
                    (a, b) => -moment(a.updatedAt).diff(moment(b.updatedAt))
                  )[0]
              }
              onPass={props.onQuizSuccess}
              onFail={props.onQuizFail}
              onNext={props.onQuizNext}
            />
          )}
        </Box>
        <Box>
          <IconButton
            disabled={nextButtonIsDisabled}
            onClick={() => {
              setIndex(Math.min(numberOfItems - 1, index + 1));
            }}
          >
            <ChevronRight
              sx={{
                ...arrowSx,
                color: nextButtonIsDisabled ? disabledColor : enabledColor,
              }}
            />
          </IconButton>
        </Box>
      </Stack>
      <Box>
        <SubchapterStepper index={index} total={numberOfItems} />
      </Box>
    </Stack>
  );
};
