import React, { FC, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getUnassignedStudents } from "../../../../api/course.service";
import { Etudiant } from "../../../../api/models";
import { useSnackbar } from "../../../../hooks/snackbar";
import { assignStudentsToCourse } from "../../../../api/student-course.service";
import {
  Avatar,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { Loader } from "../../../../component/Loader";
import { getUrl } from "../../../../utils/utils";
import { CheckCircle } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { blackAndWhite } from "../../../../styles";

export const CourseAssign: FC<{
  courseId: number;
  isOpen: boolean;
  onClose: () => void;
}> = (props) => {
  const [search, setSearch] = useState<undefined | string>(undefined);
  const query = useQuery({
    queryKey: ["unassignedStudentsOfCourse", props.courseId, search],
    queryFn: () =>
      getUnassignedStudents(props.courseId, { search, take: 5 }).then(
        (response) => response.data
      ),
  });
  const [newAssignments, setNewAssignments] = useState<Etudiant[]>([]);
  const newAssignmentIds = newAssignments.map((student) => student.id);
  const snackbar = useSnackbar();
  const assignmentMutation = useMutation({
    mutationKey: ["assignStudentsToCourse", props.courseId, newAssignments],
    mutationFn: () =>
      assignStudentsToCourse(props.courseId, newAssignmentIds)
        .then(() => {
          snackbar.show("Les étudiants sont affectés avec succès", "success");
          setNewAssignments([]);
          query.refetch().then();
          props.onClose();
        })
        .catch((e) => {
          let message =
            e.response.data?.message || "Erreur lors de l'affectation";
          snackbar.show(message, "error");
          query.refetch().then();
        }),
  });

  const students = query.data || [];

  function handleCheck(student: Etudiant) {
    const isChecked = !newAssignmentIds.includes(student.id);
    let newList = [];
    if (!isChecked) {
      newList = newAssignments.filter((s) => s.id !== student.id);
    } else {
      newList = [...newAssignments, student];
    }
    setNewAssignments(newList);
  }

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth={true}
      open={props.isOpen}
      onClose={props.onClose}
    >
      <DialogTitle>Affectation des étudiants</DialogTitle>
      <DialogContent style={{ minHeight: 500 }}>
        <div
          style={{
            padding: "8px 16px",
          }}
        >
          <InputBase
            sx={{
              backgroundColor: "#eeeeee",
              borderRadius: (theme) => `${theme.shape.borderRadius}px`,
              padding: "4px 8px",
            }}
            fullWidth={true}
            placeholder={"Rechercher par nom, prénom..."}
            type={"search"}
            autoComplete={"search"}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>

        {query.isLoading ? (
          <Loader />
        ) : (
          <List>
            {students.map((student) => (
              <ListItem
                key={student.id}
                onClick={() => {
                  handleCheck(student);
                }}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    background: "#eeeeee",
                  },
                }}
                secondaryAction={
                  <Checkbox checked={newAssignmentIds.includes(student.id)} />
                }
              >
                <ListItemAvatar>
                  <Avatar src={getUrl(student.user.userImage)} />
                </ListItemAvatar>
                <ListItemText primary={student.user.nomComplet} />
              </ListItem>
            ))}
          </List>
        )}
        {newAssignments.length > 0 && (
          <List subheader={<ListSubheader>Nouveau affectés</ListSubheader>}>
            {newAssignments.map((student) => (
              <ListItem
                key={student.id}
                onClick={() => {
                  handleCheck(student);
                }}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    background: "#eeeeee",
                  },
                }}
                secondaryAction={
                  <IconButton>
                    <CheckCircle style={{ color: "green" }} />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar src={getUrl(student.user.userImage)} />
                </ListItemAvatar>
                <ListItemText primary={student.user.nomComplet} />
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          disabled={newAssignments.length === 0}
          loading={assignmentMutation.isLoading}
          {...blackAndWhite}
          onClick={() => {
            assignmentMutation.mutate();
          }}
        >
          Sauvegarder
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
