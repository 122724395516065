import { FC, useEffect } from "react";
import { CourseSelection, QuizType, SubchapterType } from "../dto";
import {
  Chapitre,
  EtudiantProject,
  Formation,
  PresenceWorkshop,
  StudentQuiz,
} from "../../../../api/models";
import { ContentOfSubchapter } from "./ContentOfSubchapter";
import { CourseProject } from "../../projects/CourseProject";
import {
  getIndexOfProject,
  getTotalProjects,
} from "../../../../api/course.service";
import { ContentOfConclusion } from "./ContentOfConclusion";
import { ContentOfWorkshop } from "./ContentOfWorkshop";
import { compareByField } from "../../../../utils/utils";
import { findSubchapterInCourse } from "../../../../api/subchapter.service";
import {
  getConcreteSelections,
  getIndexes,
  getNextSelection,
} from "../../../../api/course-content.service";
import { useCurrentCourse } from "../../../../hooks/student/hooks";
import { useUpdateCurrentCourseCursor } from "../hooks";
import moment from "moment";

function useMoveCursorOfWorkshop(
  course: Formation,
  onUpdate: (newSelection: CourseSelection) => void,
  selection?: CourseSelection
) {
  const handleWorkshopCursor = () => {
    if (selection?.type !== "workshop") return;

    const workshopDate = moment(selection.value.date);
    const now = moment(new Date());

    const menuItems = getConcreteSelections(course);
    const indexes = getIndexes({
      currentSelection: selection,
      course,
    });
    const hasNextItems = indexes.indexOfSelection + 1 < menuItems.length;
    const selectionIsAfterCourse =
      indexes.indexOfCursor <= indexes.indexOfSelection;

    if (now.isAfter(workshopDate) && hasNextItems && selectionIsAfterCourse) {
      const next = menuItems[indexes.indexOfSelection + 1];
      onUpdate(next);
    }
  };

  useEffect(() => {
    handleWorkshopCursor();
  }, [selection]);
}

type Props = {
  selection?: CourseSelection;
  course: Formation;
  studentProjects: EtudiantProject[];
  studentQuizzes?: StudentQuiz[];
  studentWorkshopAttendance: PresenceWorkshop[];
  onUpdateProject: () => void;
  onSubchapterChangeIndex: (nextSelection: CourseSelection) => void;
  onQuizSuccess: (studentQuiz: StudentQuiz) => void;
  onQuizFail: (studentQuiz: StudentQuiz) => void;
  onQuizNext: () => void;
  onProjectNext: () => void;
};
export const CourseContent: FC<Props> = (props) => {
  const mutation = useUpdateCurrentCourseCursor();
  const currentCourse = useCurrentCourse();

  useMoveCursorOfWorkshop(
    { ...props.course, ...currentCourse },
    (selection) => {
      mutation.updateCursor(props.course, selection);
    },
    props.selection
  );

  const onSubchapterChangeIndex = (nextSelection: CourseSelection) => {
    props.onSubchapterChangeIndex(nextSelection);
  };

  switch (props.selection?.type) {
    case undefined: {
      return <div>Please select an option from the menu</div>;
    }
    case "subchapter": {
      const selection = props.selection as SubchapterType;
      return (
        <ContentOfSubchapter
          subchapter={selection.value}
          studentQuizzes={
            props.studentQuizzes?.filter(
              (studentQuiz) => studentQuiz.qcmId === selection.value.qcm?.id
            ) || []
          }
          onChangeIndex={onSubchapterChangeIndex}
          onQuizSuccess={props.onQuizSuccess}
          onQuizFail={props.onQuizFail}
          onQuizNext={props.onQuizNext}
        />
      );
    }
    case "project": {
      const selection = props.selection;
      const chapter = props.course.chapitres.find(
        (chapter) => chapter.project.id === props.selection?.value.id
      ) as Chapitre;

      return (
        <CourseProject
          chapterTitle={chapter.title}
          index={getIndexOfProject(props.course, props.selection.value)}
          project={props.selection.value}
          total={getTotalProjects(props.course)}
          studentProject={props.studentProjects.find(
            (studentProject) => studentProject.projectId === selection.id
          )}
          onUpdate={props.onUpdateProject}
          onNext={props.onProjectNext}
          action={
            !getNextSelection(props.course, selection)
              ? undefined
              : {
                  title: "Suivant",
                  onClick: () => {
                    props.onProjectNext();
                  },
                }
          }
        />
      );
    }
    case "conclusion": {
      return (
        <ContentOfConclusion
          fileUrl={props.selection.value.recap_url}
          onLoad={() => {
            const fullCourse = {
              ...currentCourse,
              ...props.course,
            };
            if (!props.selection) return;
            const nextSelection = getNextSelection(fullCourse, props.selection);
            if (!nextSelection) return;
            const indexes = getIndexes({
              course: fullCourse,
              currentSelection: nextSelection,
            });
            if (indexes.indexOfSelection > indexes.indexOfCursor) {
              mutation.updateCursor(props.course, nextSelection);
            }
          }}
        />
      );
    }
    case "workshop": {
      const indexOfWorkshop = props.course.chapitres
        .sort(compareByField("order"))
        .map((chapter) => chapter.Workshop?.id)
        .filter(Boolean)
        .findIndex((id) => id === props.selection?.value.id);
      return (
        <ContentOfWorkshop
          numberOfWorkshop={indexOfWorkshop + 1}
          workshop={props.selection.value}
          attendance={props.studentWorkshopAttendance.find(
            (attendance) => attendance.WorkshopId === props.selection?.id
          )}
        />
      );
    }
    case "page": {
      const page = props.selection.value;
      const subchapter = findSubchapterInCourse(
        page.SousChapitreId,
        props.course
      );
      const index = subchapter!!.pages.findIndex(
        (p) => p.id === props.selection!!.id
      );

      return (
        <ContentOfSubchapter
          index={index}
          subchapter={subchapter!!}
          studentQuizzes={
            props.studentQuizzes?.filter(
              (studentQuiz) => studentQuiz.qcmId === subchapter!!.qcm?.id
            ) || []
          }
          onChangeIndex={onSubchapterChangeIndex}
          onQuizSuccess={props.onQuizSuccess}
          onQuizFail={props.onQuizFail}
          onQuizNext={props.onQuizNext}
        />
      );
    }
    case "quiz": {
      const selection = props.selection as QuizType;
      const quiz = selection.value;
      if (!quiz) return <>Quiz not found</>;
      const subchapter = findSubchapterInCourse(
        quiz.SousChapitreId,
        props.course
      );
      if (!subchapter) return <>Quiz has no subchapter</>;
      const index = subchapter!!.pages.length;

      return (
        <ContentOfSubchapter
          subchapter={subchapter!!}
          studentQuizzes={
            props.studentQuizzes?.filter(
              (studentQuiz) => studentQuiz.qcmId === subchapter!!.qcm?.id
            ) || []
          }
          index={index}
          onQuizSuccess={props.onQuizSuccess}
          onQuizFail={props.onQuizFail}
          onQuizNext={props.onQuizNext}
          onChangeIndex={onSubchapterChangeIndex}
        />
      );
    }
    default: {
      return <div>Unknown selection type</div>;
    }
  }
};
