import * as React from "react";
import { FC, useEffect } from "react";
import { Project } from "../../../../../api/models";
import { useSnackbar } from "../../../../../hooks/snackbar";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { updateProject } from "../../../../../api/project.service";
import { Button, Grid, TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "../ContentPage.css";
// @ts-ignore
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { baseUrl } from "../../../../../env";

export const ProjectContent: FC<{
  project: Project;
  onUpdate: (project: Project) => void;
}> = (props) => {
  const snackbar = useSnackbar();
  const form = useForm<{
    enonce: string;
    deadline: string;
  }>({
    defaultValues: {
      enonce: props.project.enonce,
      deadline: props.project.deadline,
    },
  });
  const mutation = useMutation<any, any, Partial<Project>>(
    ["updateProject", props.project.id],
    (project) =>
      updateProject(props.project.id, project).catch(() => {
        snackbar.show("Erreur lors de la mise à jour du projet", "error");
      })
  );

  useEffect(() => {
    form.setValue("enonce", props.project.enonce);
    form.setValue("deadline", props.project.deadline);
  }, [props.project.id]);

  if (!props.project) {
    return <div>No project found</div>;
  }

  return (
    <form
      onSubmit={form.handleSubmit((values) => {
        mutation.mutate({
          enonce: values.enonce,
          deadline: values.deadline,
        });
      })}
    >
      <Grid container direction={"column"} spacing={2}>
        <Grid item>
          <div className={"project-statement-container"}>
            <div className={"mb-1"}>Enoncé</div>
            <div>
              <CKEditor
                id={"project_statement_input"}
                editor={ClassicEditor}
                data={form.getValues().enonce}
                config={{
                  ckfinder: {
                    uploadUrl: `${baseUrl}/uploads`,
                  },
                }}
                onReady={(editor: any) => {}}
                onChange={(event: any, editor: any) => {
                  const data = editor.getData();
                  form.setValue("enonce", data);
                }}
                onBlur={(event: any, editor: any) => {}}
                onFocus={(event: any, editor: any) => {}}
                onError={(details: any) => {}}
              />
            </div>
          </div>
        </Grid>
        <Grid item>
          <div className={"mb-1"}>Deadline</div>
          <div>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Controller
                control={form.control}
                render={(props) => {
                  return (
                    <DateTimePicker
                      {...props.field}
                      renderInput={(props: any) => <TextField {...props} />}
                      label={""}
                    />
                  );
                }}
                name={"deadline"}
              />
            </LocalizationProvider>
          </div>
        </Grid>
        <Grid item>
          <Button
            type={"submit"}
            variant={"contained"}
            style={{
              backgroundColor: "black",
              paddingLeft: "2rem",
              paddingRight: "2rem",
            }}
          >
            Sauvegarder
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
